Main{
  background-color: #C2DED1;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*.img{*/
/*  height: 200px;*/
/*  width: 200px;*/
/*}*/


detailCard{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
}


.smoothTransition{
  transition: 1s;
}
.FilesDragAndDrop__area {
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  font-size: 24px;
  color: #555555;
  border: 2px #333333 dashed;
  border-right: 0;
  border-radius: 12px 0 0 12px;
  background-color: white;
  width:20vw;
}
.area__icon {
  font-size: 64px;
  margin-top: 20px;
}

.selectedFiles{
  border:2px black solid;
  border-radius: 0 12px 12px 0;
}
.scrollParent{
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.scrollChild{
  width: 100%;
  height: 99%;
  overflow: auto;
  padding-right: 15px;
}

.error{
  color:red;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 0;
}

.loading{
  color:green;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 0;
}

.keyScreen{
  display: flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  height: 100%;
  width: 100%;
  background-color: blue;
}

.centerText{
  text-align: center;
  margin:1em
}
.keyText{
  font-size: 22px;
  color: green;
  font-weight: bold;
}
.boldText{
  font-weight: bold;
}
.keyTextField{
  width: max(200px,60%);
  padding: 10px;
  border-radius: 22px;
  border: 2px solid rgb(0, 0, 0);
  margin-top: 20px;
}
.fullScreen{
height: 20vw;
}
.file-card{
  /*border-radius: 12px;*/
  border-bottom: 1px solid;
  margin: auto;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.column {
  float: left;
  width: 33.3% !important;
  margin-bottom: 16px;
  padding: 0 8px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 8px;
}

.about-section {
  padding: 50px;
  border-radius: 1em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 2vw;
  margin-bottom: 2vw;
}

.container {
  padding: 0 16px;
  margin-top: 2vw;
}

.container::after, .row::after {
  content: "";
  clear: both;
  display: table;
}

.title {
  color: grey;
}

.button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.button:hover {
  background-color: #555;
}

@media screen and (max-width: 650px) {
  .column {
    width: 100%;
    display: block;
  }
}